
import { Component, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import ExportData from '@/components/export-data/index.vue'
import { apiHelpContentDeleteData, apiHelpContentGetList, apiHelpContentUpdateData } from '@/api/instructions'
import { RequestPaging } from '@/utils/util'
import { PageMode } from '@/utils/type'
import { processInput } from "@/utils/util"

@Component({
    components: {
        LsDialog,
        LsPagination,
        ExportData
    }
})
export default class ArticleLists extends Vue {
    /** S Data **/

    // 分页
    pager: RequestPaging = new RequestPaging()
    processInput = processInput

    /** E Data **/

    /** S Methods **/

    // 更改显示开关状态
    changeStatus(value: 0 | 1, row: any) {
        apiHelpContentUpdateData({
            id: row.id,
            status: value
        })
            .then(() => {
                this.getArticleList()
            })
            .catch((err: any) => {
                console.log('err', err)
            })
    }

    // 新增资讯文章
    goArticleAdd() {
        this.$router.push({
            path: '/instructions/article_edit',
            query: {
                mode: PageMode.ADD
            }
        })
    }

    // 编辑资讯文章
    goArticleEdit(data: any) {
        this.$router.push({
            path: '/instructions/article_edit',
            query: {
                id: data.id,
                mode: PageMode.EDIT
            }
        })
    }

    // 获取资讯文章列表
    getArticleList() {
        this.pager
            .request({
                callback: apiHelpContentGetList,
                params: {}
            })
            .catch((err: any) => {
                console.log('err', err)
            })
    }

    // 删除资讯文章
    onArticleDel(row: any) {
        apiHelpContentDeleteData({
            id: row.id
        }).then(() => {
            this.getArticleList()
        })
    }

    /** E Methods **/

    /** S Life Cycle **/
    created() {
        this.getArticleList()
    }

    /** E Life Cycle **/
}
